import { AxiosInstance, AxiosResponse } from "axios";
import { fetcher } from "../../configurations";
import { Incidence, IncidenceVideo } from "../../interfaces";
import { IncidenceFilters, IncidenceResponse, IncidenceStatus } from "../../interfaces/incidence.interface";

export class IncidenceRepository {
    private lmBootApi: AxiosInstance;

    constructor() {
        this.lmBootApi = fetcher(process.env.REACT_APP_URL_API_2 as string);
    }

    getIncidences(data: IncidenceFilters): Promise<AxiosResponse<IncidenceResponse>> {
        // return this.lmBootApi.get<IncidenceResponse>("/incidences/web", { params: data });
        return this.lmBootApi.get<IncidenceResponse>("/incidences/web", { params: data });
    }
    getIncidenceById(incidenceId: number): Promise<AxiosResponse<Incidence>> {
        // return this.lmBootApi.get<Incidence>(`/incidences/${incidenceId}`);
        return this.lmBootApi.get<Incidence>(`/incidences/${incidenceId}`);
    }
    getIncidenceInfo(data: string): Promise<AxiosResponse<Incidence[]>> {
        return this.lmBootApi.get<Incidence[]>("/incidences/web", { params: { ids: data } });
    }
    getIncidenceStatus(): Promise<AxiosResponse<IncidenceStatus[]>> {
        return this.lmBootApi.get<IncidenceStatus[]>("/incidences/listStatus");
    }
    getIncidenceVideos(incidenceId: number): Promise<AxiosResponse<IncidenceVideo[]>> {
        return this.lmBootApi.get<IncidenceVideo[]>(`/incidences/videos/${incidenceId}`);
    }
    updateIncidenceStatus(incidenceId: number, data: any): Promise<AxiosResponse<{ message: string }>> {
        return this.lmBootApi.put<{ message: string }>(`/incidences/updateStatus`, {incidenceId, data});
    }
    updateIncidenceMeasures(incidenceId: number, data: any): Promise<AxiosResponse<{ message: string }>> {
        return this.lmBootApi.put<{ message: string }>(`/incidences/measures`, {incidenceId, data});
    }
    updateIncidenceResolution(incidenceId: number, data: any): Promise<AxiosResponse<{ message: string }>> {
        return this.lmBootApi.put<{ message: string }>(`/incidences/resolution`, {incidenceId, data});
    }
    associateIncidenceImages(formData: any): Promise<AxiosResponse<{ message: string }>> {
        return this.lmBootApi.post<{ message: string }>(`/incidences/associate-additional-images`, formData, {headers: { "Content-Type": "multipart/form-data" }});
    }
    associateIncidenceFiles(formData: any): Promise<AxiosResponse<{ message: string }>> {
        return this.lmBootApi.post<{ message: string }>(`/incidences/associate-additional-files`, formData, {headers: { "Content-Type": "multipart/form-data" }});
    }
    removeIncidenceFiles(incidenceId: number, fileId: any): Promise<AxiosResponse<{ message: string }>> {
        return this.lmBootApi.put<{ message: string }>(`/incidences/remove-additional-file`, {incidenceId, fileId});
    }
    getVestimentas(): Promise<AxiosResponse<any>> {
        return this.lmBootApi.get<any>("/vestimentas");
    }
}

