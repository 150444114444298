import { showAlert } from "./alert";
import { ReferralProcess } from "../../api";
import { ReferralError, ReferralOrderFilters } from "../../interfaces";
import { ReferralTypes } from "../types";

export const getReferralOrdersAction = (filters: ReferralOrderFilters) => async (dispatch: (arg: Record<string, any>) => any) => {
        dispatch({
            type: ReferralTypes.GET_REFERRAL_ORDERS_LOADING,
            payload: {
                loading: true,
            },
        });
        try {
            const referralProcess = new ReferralProcess();
            const response = await referralProcess.getReferralOrders(filters);
            return dispatch({
                type: ReferralTypes.GET_REFERRAL_ORDERS_SUCCESS,
                payload: {
                    referralOrders: response.data,
                    meta: response.meta,
                    filters,
                    loading: false,
                    success: true,
                },
            });
        } catch (error) {
            return dispatch({
                type: ReferralTypes.GET_REFERRAL_ORDERS_FAILURE,
                payload: {
                    loading: false,
                    success: false,
                },
            });
        }
    };
export const getReferralOrderByIdAction =
    (referralCode: number) => async (dispatch: (arg: Record<string, any>) => any) => {
        dispatch({
            type: ReferralTypes.GET_REFERRAL_ORDER_BY_ID_LOADING,
            payload: {
                loading: true,
            },
        });
        try {
            const referralProcess = new ReferralProcess();
            const response = await referralProcess.getReferralOrderById(referralCode);
            return dispatch({
                type: ReferralTypes.GET_REFERRAL_ORDER_BY_ID_SUCCESS,
                payload: {
                    selectedReferralOrders: response,
                    loading: false,
                },
            });
        } catch (error) {
            return dispatch({
                type: ReferralTypes.GET_REFERRAL_ORDER_BY_ID_FAILURE,
                payload: {
                    loading: false,
                    success: false,
                },
            });
        }
    };
export const clearSelectedReferralOrderAction = () => async (dispatch: (arg: Record<string, any>) => any) => {
    dispatch({
        type: ReferralTypes.CLEAR_REFERRAL_ORDER_SUCCESS,
        payload: {
            loading: false,
        },
    });
};

export const getReferralPlacedOrdersByIdAction =
    (referralCode: number, filterReferral: boolean) => async (dispatch: (arg: Record<string, any>) => any) => {
        dispatch({
            type: ReferralTypes.GET_REFERRAL_PLACED_ORDERS_BY_ID_LOADING,
            payload: {
                loading: true,
            },
        });
        try {
            const referralProcess = new ReferralProcess();
            const response = await referralProcess.getReferralPlacedOrdersById(referralCode, filterReferral);
            return dispatch({
                type: ReferralTypes.GET_REFERRAL_PLACED_ORDERS_BY_ID_SUCCESS,
                payload: {
                    selectedReferralPlacedOrders: response,
                    loading: false,
                },
            });
        } catch (error) {
            return dispatch({
                type: ReferralTypes.GET_REFERRAL_PLACED_ORDERS_BY_ID_FAILURE,
                payload: {
                    loading: false,
                    success: false,
                },
            });
        }
    };


export const clearSelectedReferralPlacedOrdersAction = () => async (dispatch: (arg: Record<string, any>) => any) => {
    dispatch({
        type: ReferralTypes.CLEAR_REFERRAL_PLACED_ORDERS_SUCCESS,
        payload: {
            loading: false,
        },
    });
};

export const exportReferralsAction = async (filters: ReferralOrderFilters, format: any) => {
    const referralProcess = new ReferralProcess();
    let response = await referralProcess.getReferralOrders(filters);
    let report ={
        data: response.data,
        headers: format
    }
    return await referralProcess.exportReport(report);
};


export const exportReportAction = async (data: any) => {
    const referralProcess = new ReferralProcess();
    return await referralProcess.exportReport(data);
};
