import { IncidenceRepository } from "../repositories";
import { IncidenceResponse, IncidenceFilters, Incidence } from "../../interfaces/incidence.interface";

export class IncidenceProcess {
    private incidenceRepository: IncidenceRepository;

    constructor() {
        this.incidenceRepository = new IncidenceRepository();
    }

    async getIncidences(filters: IncidenceFilters): Promise<IncidenceResponse> {
        const response = await this.incidenceRepository.getIncidences(filters);
        const {
            data: { data, meta },
        } = response;
        return {
            data: data,
            meta: meta,
        };
    }
    async getIncidenceById(incidenceId: number): Promise<Incidence> {
        const response = await this.incidenceRepository.getIncidenceById(incidenceId);
        return response.data;
    }
    async getIncidenceStatus(): Promise<IncidenceResponse> {
        const response = await this.incidenceRepository.getIncidenceStatus();
        return {
            incidenceStatus: response.data,
        };
    }
    async getIncidenceVideos(incidenceId: number): Promise<IncidenceResponse> {
        const response = await this.incidenceRepository.getIncidenceVideos(incidenceId);
        return {
            incidenceVideos: response.data,
        };
    }
    async updateIncidenceStatus(incidenceId: number, data: any): Promise<{ message: string }> {
        const response = await this.incidenceRepository.updateIncidenceStatus(incidenceId, data);
        return { message: response.data.message };
    }
    async updateIncidenceMeasures(incidenceId: number, data: any): Promise<{ message: string }> {
        const response = await this.incidenceRepository.updateIncidenceMeasures(incidenceId, data);
        return { message: response.data.message };
    }
    async updateIncidenceResolution(incidenceId: number, data: any): Promise<{ message: string }> {
        const response = await this.incidenceRepository.updateIncidenceResolution(incidenceId, data);
        return { message: response.data.message };
    }
    async associateIncidenceImages(formData: any): Promise<{ message: string }> {
        const response = await this.incidenceRepository.associateIncidenceImages(formData);
        return { message: response.data.message };
    }

    async associateIncidenceFiles(formData: any): Promise<{ message: string }> {
        const response = await this.incidenceRepository.associateIncidenceFiles(formData);
        return { message: response.data.message };
    }

    async removeIncidenceFiles(incidenceId: number, fileId: any): Promise<{ message: string }> {
        const response = await this.incidenceRepository.removeIncidenceFiles(incidenceId, fileId);
        return { message: response.data.message };
    }

    async getVestimentas(): Promise<any> {
        const response = await this.incidenceRepository.getVestimentas();
        return response.data;
    }
}
