import { IncidenceFilters } from "../../interfaces/incidence.interface";
import { IncidenceTypes } from "../types/incidence.types";
import { IncidenceProcess } from "../../api/process/incidence.process";
export const getIncidencesAction =
    (filters: IncidenceFilters) => async (dispatch: (arg: Record<string, any>) => any) => {
        dispatch({
            type: IncidenceTypes.GET_INCIDENCES_LOADING,
            payload: {
                loading: true,
            },
        });
        try {
            const incidenceProcess = new IncidenceProcess();
            const response = await incidenceProcess.getIncidences(filters);
            return dispatch({
                type: IncidenceTypes.GET_INCIDENCES_SUCCESS,
                payload: {
                    incidences: response.data,
                    meta: response.meta,
                    filters,
                    loading: false,
                    success: true,
                },
            });
        } catch (error) {
            return dispatch({
                type: IncidenceTypes.GET_INCIDENCES_FAILURE,
                payload: {
                    loading: false,
                    success: false,
                },
            });
        }
    };
export const getIncidenceByIdAction = (incidenceId: number) => async (dispatch: (arg: Record<string, any>) => any) => {
    dispatch({
        type: IncidenceTypes.GET_INCIDENCE_BY_ID_LOADING,
        payload: {
            loading: true,
        },
    });
    try {
        const incidenceProcess = new IncidenceProcess();
        const response = await incidenceProcess.getIncidenceById(incidenceId);
        return dispatch({
            type: IncidenceTypes.GET_INCIDENCE_BY_ID_SUCCESS,
            payload: {
                selectedIncidence: response,
                loading: false,
            },
        });
    } catch (error) {
        console.log('error: ', error);
        return dispatch({
            type: IncidenceTypes.GET_INCIDENCE_BY_ID_FAILURE,
            payload: {
                loading: false,
                success: false,
            },
        });
    }
};
export const clearSelectedIncidenceAction = () => async (dispatch: (arg: Record<string, any>) => any) => {
    dispatch({
        type: IncidenceTypes.CLEAR_INCIDENCE_SUCCESS,
        payload: {
            loading: false,
        },
    });
};
export const getIncidenceStatusAction = () => async (dispatch: (arg: Record<string, any>) => any) => {
    dispatch({
        type: IncidenceTypes.GET_INCIDENCE_STATUS_LOADING,
        payload: {
            loading: true,
        },
    });
    try {
        const incidenceProcess = new IncidenceProcess();
        const response = await incidenceProcess.getIncidenceStatus();
        return dispatch({
            type: IncidenceTypes.GET_INCIDENCE_STATUS_SUCCESS,
            payload: {
                incidenceStatus: response.incidenceStatus,
                loading: false,
                success: true,
            },
        });
    } catch (error) {
        return dispatch({
            type: IncidenceTypes.GET_INCIDENCE_STATUS_FAILURE,
            payload: {
                loading: false,
                success: false,
            },
        });
    }
};

export const updateIncidenceStatusAction = (incidenceId: number, data: any) => async (dispatch: (arg: Record<string, any>) => any) => {
        dispatch({
            type: IncidenceTypes.UPDATE_INCIDENCE_STATUS_LOADING,
            payload: {
                loading: true,
            },
        });
        try {
            const incidenceProcess = new IncidenceProcess();
            await incidenceProcess.updateIncidenceStatus(incidenceId, data);
            setTimeout(dispatch(getIncidenceByIdAction(incidenceId)), 500);
            return dispatch({
                type: IncidenceTypes.UPDATE_INCIDENCE_STATUS_SUCCESS,
                payload: {
                    loading: true,
                    success: true,
                },
            });
        } catch (error) {
            return dispatch({
                type: IncidenceTypes.UPDATE_INCIDENCE_STATUS_FAILURE,
                payload: {
                    loading: false,
                    success: false,
                },
            });
        }
    };

export const updateIncidenceMeasuresAction = (incidenceId: number, data: any) => async (dispatch: (arg: Record<string, any>) => any) => {
        dispatch({
            type: IncidenceTypes.UPDATE_INCIDENCE_MEASURES_LOADING,
            payload: {
                loading: true,
            },
        });
        try {
            const incidenceProcess = new IncidenceProcess();
            await incidenceProcess.updateIncidenceMeasures(incidenceId, data);
            return dispatch({
                type: IncidenceTypes.UPDATE_INCIDENCE_MEASURES_SUCCESS,
                payload: {
                    loading: false,
                    success: true,
                },
            });
        } catch (error) {
            return dispatch({
                type: IncidenceTypes.UPDATE_INCIDENCE_MEASURES_FAILURE,
                payload: {
                    loading: false,
                    success: false,
                },
            });
        }
    };

    export const updateIncidenceResolutionAction = (incidenceId: number, data: any) => async (dispatch: (arg: Record<string, any>) => any) => {
        dispatch({
            type: IncidenceTypes.UPDATE_INCIDENCE_RESOLUTION_LOADING,
            payload: {
                loading: true,
            },
        });
        try {
            const incidenceProcess = new IncidenceProcess();
            await incidenceProcess.updateIncidenceResolution(incidenceId, data);
            // setTimeout(dispatch(getIncidenceByIdAction(incidenceId)), 500);
            return dispatch({
                type: IncidenceTypes.UPDATE_INCIDENCE_RESOLUTION_SUCCESS,
                payload: {
                    loading: false,
                    success: true,
                },
            });
        } catch (error) {
            return dispatch({
                type: IncidenceTypes.UPDATE_INCIDENCE_RESOLUTION_FAILURE,
                payload: {
                    loading: false,
                    success: false,
                },
            });
        }
    };

    export const associateIncidenceImagesAction = (incidenceId: number, formData: any) => async (dispatch: (arg: Record<string, any>) => any) => {
        dispatch({
            type: IncidenceTypes.UPDATE_INCIDENCE_RESOLUTION_LOADING,
            payload: {
                loading: true,
            },
        });
        try {
            const incidenceProcess = new IncidenceProcess();
            const response = await incidenceProcess.associateIncidenceImages(formData);
            console.log('response: ', response);
            setTimeout(dispatch(getIncidenceByIdAction(incidenceId)), 500);
            return dispatch({
                type: IncidenceTypes.UPDATE_INCIDENCE_RESOLUTION_SUCCESS,
                payload: {
                    loading: false,
                    success: true,
                },
            });
        } catch (error) {
            console.log('error: ', error);
            return dispatch({
                type: IncidenceTypes.UPDATE_INCIDENCE_RESOLUTION_FAILURE,
                payload: {
                    loading: false,
                    success: false,
                },
            });
        }
    };

    export const associateIncidenceFilesAction = (incidenceId: number, formData: any) => async (dispatch: (arg: Record<string, any>) => any) => {
        dispatch({
            type: IncidenceTypes.UPDATE_INCIDENCE_RESOLUTION_LOADING,
            payload: {
                loading: true,
            },
        });
        try {
            const incidenceProcess = new IncidenceProcess();
            const response = await incidenceProcess.associateIncidenceFiles(formData);
            console.log('response: ', response);
            setTimeout(dispatch(getIncidenceByIdAction(incidenceId)), 500);
            return dispatch({
                type: IncidenceTypes.UPDATE_INCIDENCE_RESOLUTION_SUCCESS,
                payload: {
                    loading: false,
                    success: true,
                },
            });
        } catch (error) {
            console.log('error: ', error);
            return dispatch({
                type: IncidenceTypes.UPDATE_INCIDENCE_RESOLUTION_FAILURE,
                payload: {
                    loading: false,
                    success: false,
                },
            });
        }
    };

    export const removeIncidenceFilesAction = (incidenceId: number, fileId: any) => async (dispatch: (arg: Record<string, any>) => any) => {
        dispatch({
            type: IncidenceTypes.UPDATE_INCIDENCE_RESOLUTION_LOADING,
            payload: {
                loading: true,
            },
        });
        try {
            const incidenceProcess = new IncidenceProcess();
            const response = await incidenceProcess.removeIncidenceFiles(incidenceId, fileId);
            console.log('response: ', response);
            setTimeout(dispatch(getIncidenceByIdAction(incidenceId)), 500);
            return dispatch({
                type: IncidenceTypes.UPDATE_INCIDENCE_RESOLUTION_SUCCESS,
                payload: {
                    loading: false,
                    success: true,
                },
            });
        } catch (error) {
            console.log('error: ', error);
            return dispatch({
                type: IncidenceTypes.UPDATE_INCIDENCE_RESOLUTION_FAILURE,
                payload: {
                    loading: false,
                    success: false,
                },
            });
        }
    };

export const getIncidenceVideoAction = (incidenceId: number) => async (dispatch: (arg: Record<string, any>) => any) => {
    dispatch({
        type: IncidenceTypes.GET_INCIDENCE_VIDEOS_LOADING,
        payload: {
            loading: true,
        },
    });
    try {
        const incidenceProcess = new IncidenceProcess();
        const response = await incidenceProcess.getIncidenceVideos(incidenceId);

        return dispatch({
            type: IncidenceTypes.GET_INCIDENCE_VIDEOS_SUCCESS,
            payload: {
                incidenceVideos: response.incidenceVideos,
                loading: false,
                success: true,
            },
        });
    } catch (error) {
        return dispatch({
            type: IncidenceTypes.GET_INCIDENCE_VIDEOS_FAILURE,
            payload: {
                loading: false,
                success: false,
            },
        });
    }
};
export const clearIncidenceVideoAction = () => async (dispatch: (arg: Record<string, any>) => any) => {
    dispatch({
        type: IncidenceTypes.CLEAR_INCIDENCE_VIDEOS_SUCCESS,
        payload: {
            loading: false,
        },
    });
};

export const getVestimentas = () => async (dispatch: (arg: Record<string, any>) => any) => {
    dispatch({
        type: IncidenceTypes.GET_VESTIMENTAS_LOADING,
        payload: {
            loading: true,
        },
    });
    try {
        const incidenceProcess = new IncidenceProcess();
        const vestimentas = await incidenceProcess.getVestimentas();
        return dispatch({
            type: IncidenceTypes.GET_VESTIMENTAS_SUCCESS,
            payload: {
                vestimentas,
                loading: false,
                success: true,
            },
        });
    } catch (error) {
        console.log('error: ', error);
        return dispatch({
            type: IncidenceTypes.GET_VESTIMENTAS_FAILURE,
            payload: {
                loading: false,
                success: false,
            },
        });
    }
};

export const setFilterDate = (filterDate: string) => (dispatch: (arg: Record<string, any>) => any) => {
    return dispatch({
        type: IncidenceTypes.SET_INCIDENCE_FILTER_DATE,
        payload: {
            loading: false,
            filterDate,
        },
    });
}
export const setFilterCustomer = (filterCustomer: string) => (dispatch: (arg: Record<string, any>) => any) => {
    return dispatch({
        type: IncidenceTypes.SET_INCIDENCE_FILTER_CUSTOMER,
        payload: {
            loading: false,
            filterCustomer,
        },
    });
}
export const setFilterOrder = (filterOrder: string) => (dispatch: (arg: Record<string, any>) => any) => {
    return dispatch({
        type: IncidenceTypes.SET_INCIDENCE_FILTER_ORDER,
        payload: {
            loading: false,
            filterOrder,
        },
    });
}
export const setFilterStatus = (filterStatus: string) => (dispatch: (arg: Record<string, any>) => any) => {
    return dispatch({
        type: IncidenceTypes.SET_INCIDENCE_FILTER_STATUS,
        payload: {
            loading: false,
            filterStatus,
        },
    });
}