import { IssueFilters } from "../../interfaces/issue.interface";
import { IssueTypes } from "../types/issue.types";
import { IssueProcess } from "../../api/process/issue.process";
export const getIssuesAction =
    (filters: IssueFilters) => async (dispatch: (arg: Record<string, any>) => any) => {
        dispatch({
            type: IssueTypes.GET_ISSUES_LOADING,
            payload: {
                loading: true,
            },
        });
        try {
            const issueProcess = new IssueProcess();
            const response = await issueProcess.getIssues(filters);
            return dispatch({
                type: IssueTypes.GET_ISSUES_SUCCESS,
                payload: {
                    issueOrders: response.data,
                    meta: response.meta,
                    filters,
                    loading: false,
                    success: true,
                },
            });
        } catch (error) {
            return dispatch({
                type: IssueTypes.GET_ISSUES_FAILURE,
                payload: {
                    loading: false,
                    success: false,
                },
            });
        }
    };

export const updatePaymentAuthAction = (orderId: number, data: any) => async (dispatch: (arg: Record<any, any>) => any) => {
    dispatch({
        type: IssueTypes.UPDATE_ISSUE_PAYMENT_AUTH_LOADING,
        payload: {
            loading: true,
        },
    });
    try {
        const issueProcess = new IssueProcess();
        let auth = {
            authCode: data.authCode,
            last4Digits: data.last4Digits,
        }
        await issueProcess.updatePaymentAuth(orderId, auth);
        return dispatch({
            type: IssueTypes.UPDATE_ISSUE_PAYMENT_AUTH_SUCCESS,
            payload: {
                loading: true,
                success: true,
            },
        });
    } catch (error) {
        return dispatch({
            type: IssueTypes.UPDATE_ISSUE_PAYMENT_AUTH_FAILURE,
            payload: {
                loading: false,
                success: false,
            },
        });
    }
};

export const confirmOrder = (orderId: number, ticketId: number) => async (dispatch: (arg: Record<any, any>) => any) => {
    dispatch({
        type: IssueTypes.CONFIRM_ORDER_LOADING,
        payload: {
            loading: true,
        },
    });
    try {
        const issueProcess = new IssueProcess();
        await issueProcess.confirmOrder(orderId, ticketId);
        return dispatch({
            type: IssueTypes.CONFIRM_ORDER_SUCCESS,
            payload: {
                loading: true,
                success: true,
            },
        });
    } catch (error) {
        return dispatch({
            type: IssueTypes.CONFIRM_ORDER_FAILURE,
            payload: {
                loading: false,
                success: false,
            },
        });
    }
};

export const archiveOrder = (orderId: number) => async (dispatch: (arg: Record<any, any>) => any) => {
    dispatch({
        type: IssueTypes.ARCHIVE_ORDER_LOADING,
        payload: {
            loading: true,
        },
    });
    try {
        const issueProcess = new IssueProcess();
        await issueProcess.archiveOrder(orderId);
        return dispatch({
            type: IssueTypes.ARCHIVE_ORDER_SUCCESS,
            payload: {
                loading: true,
                success: true,
            },
        });
    } catch (error) {
        return dispatch({
            type: IssueTypes.ARCHIVE_ORDER_FAILURE,
            payload: {
                loading: false,
                success: false,
            },
        });
    }
};

export const unArchiveOrder = (orderId: number) => async (dispatch: (arg: Record<any, any>) => any) => {
    dispatch({
        type: IssueTypes.ARCHIVE_ORDER_LOADING,
        payload: {
            loading: true,
        },
    });
    try {
        const issueProcess = new IssueProcess();
        await issueProcess.unArchiveOrder(orderId);
        return dispatch({
            type: IssueTypes.ARCHIVE_ORDER_SUCCESS,
            payload: {
                loading: true,
                success: true,
            },
        });
    } catch (error) {
        return dispatch({
            type: IssueTypes.ARCHIVE_ORDER_FAILURE,
            payload: {
                loading: false,
                success: false,
            },
        });
    }
};
